import React, { ComponentProps, ForwardedRef, forwardRef, ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import styles from "./b2x-telia-link.module.scss";
const rootClassName = "b2x-telia-link";

type Props = ComponentProps<"a"> & {
  "data-testid"?: string;
  children: ReactNode;
  darkBackground?: boolean;
  disableVisited?: boolean;
  ensureClickArea?: boolean;
  silent?: boolean;
  variant?: "text" | "standalone";
};

const TeliaLinkComponent = (props: Props, ref: ForwardedRef<HTMLAnchorElement>) => {
  useEffect(() => {
    obsoleteWarning(rootClassName, "components-link--docs");
  }, []);

  const {
    children,
    darkBackground,
    disableVisited,
    ensureClickArea,
    silent,
    variant = "text",
    ...anchorProps
  } = props;

  const className = [
    styles[rootClassName],
    darkBackground && styles[`${rootClassName}--dark-bg`],
    disableVisited && styles[`${rootClassName}--disable-visited`],
    ensureClickArea && styles[`${rootClassName}--ensure-click-area`],
    silent && styles[`${rootClassName}--silent`],
    variant && styles[`${rootClassName}--${variant}`],
  ]
    .filter((name): name is string => !!name)
    .join(" ");

  return (
    <a ref={ref} {...anchorProps} className={className}>
      {children}
    </a>
  );
};

export const TeliaLink = forwardRef(TeliaLinkComponent);
TeliaLink.displayName = "TeliaLink";
